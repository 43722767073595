/* eslint-disable max-len */
export const messages = {
    en: {
        resume: {
            full_name: 'Имя Фамилия',
            position: 'Должность',
            email: 'Электронная почта',
            company: 'Компания',
            show_resume: 'Подробнее',
            search_placeholder: 'Поиск',
            user_info: 'User info',
            personal_information: 'Personal information and details.',
            about: 'About',
            link: 'Resume link',
            link_description: 'Resume will be opened in new window',
            experience: 'Experience',
            tg: 'Telegram'
        },
        pagination: {
            previous: 'Previous',
            next: 'Next',
            showing: 'Showing',
            to: 'to',
            of: 'from'
        },
        csv: {
            import_data: 'Import data',
            title: 'Import Data from CSV',
            subtitle: 'Import Data from CSV',
            subsubtitle: 'Import Data from CSV',
            map_data: 'Определите соответствие данных',
            parsing_results: 'Результаты парсинга',
            select_action: 'Выберите действие из списка',
            proceed_data: 'Proceed data',
            alert: 'Необходимо выбрать метод для обработки данных!'
        },
        min: 'min',
        agenda: 'Agenda',
        pages: {
            profile: 'Profile'
        },
        login_prompt: 'You have to be logged in to go further.',
        success: 'Success',
        member_message: '* Club affiliation switch',
        member: 'Member',
        foreign: 'Foreign',
        publish: 'Publish',
        unpublish: 'Hide ',
        was_published: 'Published',
        was_unpublished: 'Hidden',
        league_partners: 'League partners',
        club_partners: 'Our partners',
        newsletter: 'Subscribe to our newsletter',
        newsletter_text: 'The latest news, articles, and resources, sent to your inbox weekly.',
        newsletter_placeholder: 'Enter your email',
        newsletter_button: 'Subscribe',
        reserved: 'All rights reserved',
        app_name: 'Футбольный клуб Звезда (Санкт-Петербург, Россия)',
        news_other: 'Other news',
        rules: 'Rules',
        name: 'Name',
        password: 'Password',
        password_confirmation: 'Confirm Password',
        details_button: 'Details',
        back_button: 'Back',
        create_button: 'Create',
        cancel_button: 'Cancel',
        enable_button: 'Enable',
        confirm_button: 'Confirm',
        edit_button: 'Edit',
        disable_button: 'Disable',
        save_button: 'Save',
        select_new_button: 'Select new',
        register_button: 'Register',
        all_button: 'All',
        saved: 'Saved.',
        edit_profile: 'Edit Profile',
        logout: 'Log Out',
        story: 'write your story',
        search: 'Search on DataBase',
        hide: 'Hide section',
        show: 'Show section',
        associated_team: 'Associated with teams',
        unlink: 'Unlink',
        link: 'Link',
        only_players: 'Only for Couch and Players',
        stadium_later: 'Stadium info coming soon',
        campRegistration: {
            t1: 'Регистрация'
        },
        tableContents: {
            title: 'Table of content',
            step: 'Step ',
            chapter: 'Chapter %{variable}: '
        },
        profile_information: {
            title: 'Профиль',
            intro: 'Profile Information',
            description: 'Update your profile photo and email address.',
            photo: 'Photo',
            select_photo: 'Select new Photo',
            remove_photo: 'Remove Photo',
            save: 'Save',
            saved: 'Saved.',
            email: 'Email',
            tg: 'Telegram',
            sphere: 'Сфера интересов'
        },
        contact_information: {
            intro: 'Contact information',
            description: 'Fill the form to add your personal data.',
            first_name: 'Имя',
            second_name: 'Отчество',
            last_name: 'Фамилия',
            phone: 'Телефон',
            tg: 'Телеграм',
            city: 'Город проживания',
            social: 'Социальные сети',
            birthday: 'Дата рождения'
        },
        vacancy_request: {
            t1: 'Нет подходящей вакансии?',
            t2: 'Заполни форму, и мы пришлем тебе новые вакансии, как только они появятся:',
            position: 'Позиция',
            agree: 'Согласие на обработку персональных данных',
            request_accepted: 'Заявка отправлена',
            digest: 'Хочу дайджест',
            show_resume: 'Показывать резюме работодателям'
        },
        job_information: {
            intro: 'Информация о работе',
            description: 'Заполните или измените ваши данные.',
            company: 'Компания',
            position: 'Занимаемая должность',
            product: 'Название продукта',
            resume: 'Ссылка на действующее резюме для показа работодателям',
            level: 'Уровень',
            achivments: 'Достижения'
        },
        delete_information: {
            intro: 'Delete Account',
            description: 'Permanently delete your account.',
            alert: 'Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.',
            confirm: 'Delete Account',
            cancel: 'Cancel',
            confirmation_process: 'Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.'
        },
        update_password: {
            title: 'Update Password',
            description: 'Ensure your account is using a long, random password to stay secure.',
            label_confirm: 'Confirm Password',
            label_new: 'New Password',
            label_current: 'Current Password',
            intro: 'Update Password',
            current: 'Current Password',
            new: 'New Password',
            confirm: 'Confirm Password'
        },
        login: {
            remember_me: 'Remember me',
            l1: 'Sign in to your account',
            l2: 'Or create new account',
            r1: 'Create new account',
            r2: 'Or sign in',
            email: 'Email address',
            password: 'Password',
            password_confirmation: 'Password Confirmation',
            remember: 'Remember me',
            login: 'Sign in',
            loginAdaptive: 'Sign in',
            manage: 'Manage Account',
            tokens: 'API Tokens',
            logout: 'Log Out',
            profile: 'Profile',
            create: 'Create account',
            forgot: 'Forgot your password?',
            agree: 'I agree to the ',
            terms: 'Terms of Service',
            and: ' and ',
            policy: 'Privacy Policy',
            recovery1: 'Forgot your password? No problem.</br> Just let us know your email address, and we will email you a password reset link that will allow you to choose a new one.',
            recovery2: 'A new verification link has been sent to the email address you provided during registration.',
            recovery3: 'Please, check your email.',
            send_link: 'Email Password Reset Link',
            send_again: 'Send link again?',
            main: 'Main page',
            secure_area: 'This is a secure area of the application. Please confirm your password before continuing.',
            confirm: 'Confirm',
            already: 'Already registered?',
            register: 'Register',
            verify_request: 'Thanks for signing up! Before getting started, could you verify your email address by clicking on the link we just emailed to you? If you didn\'t receive the email, we will gladly send you another.',
            verify_again: 'Resend Verification Email',
            status: 'We have emailed your password reset link!',
            reset: 'Reset Password',
            two_factors1: 'Please confirm access to your account by entering the authentication code provided by your authenticator application.',
            two_factors2: 'Please confirm access to your account by entering one of your emergency recovery codes.',
            code_1: 'Use a recovery code',
            code_2: 'Use an authentication code',
            code: 'Code',
            recovery_code: 'Recovery Code'
        },
        two_auth: {
            intro: 'Two Factor Authentication',
            description: 'Add additional security to your account using two factor authentication.',
            enabled: 'You have enabled two factor authentication.',
            finish: 'Finish enabling two factor authentication',
            setup: 'To finish enabling two factor authentication, scan the following QR code using your phone\'s authenticator application or enter the setup key and provide the generated OTP code.\n',
            setup_key: 'Setup Key: ',
            confirm: 'Confirm',
            cancel: 'Cancel',
            disabled: 'You have not enabled two factor authentication.',
            message: 'When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone\'s Google Authenticator application.',
            read_qr: 'Two factor authentication is now enabled. Scan the following QR code using your phone\'s authenticator application.',
            code_list: 'Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.',
            enable_now: 'Enable',
            disable_now: 'Disable',
            regenerate: 'Regenerate Recovery Codes',
            show_list: 'Show Recovery Codes'
        },
        browser_sessions: {
            intro: 'Browser Sessions',
            description: 'Manage and log out your active sessions on other browsers and devices.',
            message: 'If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.',
            this_device: 'This device',
            last_active: 'Last active',
            confirm_logout: 'Log Out Other Browser Sessions',
            done: 'Done.',
            logout_other: 'Log Out Other Browser Sessions',
            password_request: 'Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.',
            cancel: 'Cancel'
        },
        hachathonRegistrationPromt: {
            t1: 'Привет. Похоже Вы не вошли в систему!',
            t2: 'Чтобы принять участие в Хакатоне необходимо представиться.<br/>Мы предлагаем пройти базовую регистрацию, которая позволит Вам быть в курсе всех новостей и предстоящих событий.<br/>Кроме того, результаты участия в наших активностях будут сохраняться в вашем личном кабинете.<br/>Либо вы можете просто зарегистрироваться на предстоящий Хакатон.',
            reg_platform: 'Регистрация на платформе',
            reg_hackathon: 'Регистрация на Хакатон'
        },
        form_validation: {
            required: 'Field is required',
            minimum_length: 'Minimum length mismatch'
        },
        registration: {
            registration: 'Registration',
            login: 'Авторизация',
            forgot_password_message: 'Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.',
            forgot_password_btn: 'Email Password Reset Link'

        },
        hr: {
            t1: 'HR центр'
        },
        scoring: {
            t1: 'Скоринг навыков'
        },
        academy: {
            t1: 'Академия',
            t2: 'Бесплатные уроки для продакт менеджеров, разработчиков и аналитиков',
            add: 'Добавить',
            courses: 'Курсы',
            materials: 'Материалы',
            seminars: 'Семинары',
            search_placeholder: 'Поиск по курсам и урокам',
            branch: 'Направление',
            level: 'Выбери свой уровень',
            type: 'Тип обучения',
            theme: 'Тема'
        },
        events: {
            events: 'События'
        },
        networking: {
            networking: 'Нетворкинг'
        },
        request: 'Оставить заявку',
        menu_name: 'menu',
        coming_soon: 'Скоро',
        telegram: 'Телеграм канал',
        follow: 'Следить за обновлениями',
        save: 'Сохранить',
        save_exit: 'Сохранить и закрыть',
        cancel: 'Отменить',
        btn_edit: 'Edit',
        btn_edit_info: 'Edit information',
        btn_delete: 'Delete',
        first_name: 'Имя',
        last_name: 'Фамилия',
        phone: 'Телефон',
        tg: 'Телеграм',
        email: 'Электронная почта',
        position: 'Позиция / специализация',
        role: 'Роль',
        other: 'Другое',
        front: 'Front-end',
        back: 'Back-end',
        full: 'Full-stack',
        design: 'Дизайнер',
        marketing: 'Маркетолог',
        pm: 'Продакт Менеджер',
        role_placeholder: 'Ваша специализация если ее нет в списке',
        hackathonRegistrationForm: {
            step01: 'Шаг 1. Информация',
            complete01: 'Пройти индивидуальную регистрацию',
            update: 'Обновить',
            forward: 'Далее',
            alert: 'Введенный вами адрес электронной почты уже зарегистрирован в системе.<br/>Для участия в мероприятии пожалуйста пройдите авторизацию.',
            team: 'Есть команда',
            no_team: 'Нет команды',
            create_team: 'Шаг 2. Собери команду',
            about: 'Шаг 2. Коротко о себе',
            new_member: 'Новый участник',
            add_member: 'Добавить участника',
            remove_mate: 'Удалить участника',
            remove_member: 'Удалить участника из состава команды',
            save_team: 'Сохранить команду и отправить уведомления',
            user_exists: 'Зарегистирован',
            new_user: 'Новый пользователь',
            reminder: 'Не забудьте уведомить друга, что вы зарегистрировали его на мероприятие',
            command_name: 'Название команды',
            company: 'Компания',
            product: 'Продукт',
            position: 'Позиция',
            level: 'Уровень',
            indi: 'Спасибо за регистрацию. Команда сформируется автоматически.',
            command: 'Спасибо за регистрацию.'
        },
        hFooter: {
            t1: 'Дополнительная информация',
            about: 'About',
            contact: 'Contact',
            FAQ: 'FAQ',
            social: 'We are on social networks: '
        },
        hackathonAdminPage: {
            name: 'Название мероприятия',
            team_name: 'Название команды',
            save: 'Сохранить',
            description: 'Описание мероприятия',
            link: 'Ссылка на мероприятие',
            type: 'Тип мероприятия',
            level: 'Уровень сложности',
            registration: 'Ссылка для регистрации',
            img: 'Логотип',
            data: 'Дата',
            productcamp: 'ProductCamp',
            hackathon: 'Хакатон',
            ideathon: 'Идеатон',
            producthon: 'Продуктон',
            junior: 'Junior',
            middle: 'Middle',
            senior: 'Senior',
            all: 'For All',
            open_form: 'Кликните на название чтобы перети к редактированию информации',
            all_tasks: 'Все задачи',
            all_teams: 'Зарегистрированные команды',
            add_team: 'Сформировать новую команду',
            back: 'Обратно к списку команд',
            free_participants: 'Нераспределенные участники',
            not_registered: 'Пользователь не заполнил личные данные'
        },
        hackathonTeam: {
            assignment_send: 'На проверку',
            assignment_placeholder: 'Ссылка на готовое задание',
            back: 'Назад',
            take_part: 'Принять участие'
        },
        menu: {
            login: 'Вход',
            events: 'События',
            edit: 'Редактировать событие',
            schedule: 'Расписание',
            schedule_admin: 'Редактировать расписание',
            admin: 'Управление',
            networking: 'Нетворкинг',
            career: 'Карьерный хаб',
            event_registration: 'Карта участника',
            logout: 'Выход',
            map: 'Карта',
            get_update: 'Подписаться на обновления'
        },
        career: {
            t1: 'Карьерный хаб',
            t2: 'Проверка компетенций, открытые вакансии партнеров и карьерные консультации',
            archive: 'Вакансии в архиве',
            desk1: 'Доска вакансий',
            desk2: 'HeadHunter & ProductCamp’21',
            desk3: 'Изучи вакансии партнеров и друзей HeadHunter, выбери то, что подходит тебе, и подай заявку',
            contract: 'договорная',
            add: 'Добавить вакансию',
            create: 'Создать/изменить вакансию',
            vacancy: 'Вакансия',
            name: 'Название',
            description: 'Описание вакансии',
            link: 'Ссылка на вакансию на HH или внутренний ресурс компании',
            company_id: 'Компания',
            location: 'Город',
            salary_min: 'Зарплата от',
            salary_max: 'Зарплата до',
            level: 'Уровень',
            office: 'Формат',
            experience: 'Опыт',
            type: 'Категория',
            status: 'Статус',
            published_at: 'Активна',
            open_full_form: 'Открыть дополнительные поля',
            close_full_form: 'Скрыть дополнительные поля',
            about: 'О продукте',
            tasks: 'Задачи',
            requirements: 'Требования',
            conditions: 'Условия',
            skills: 'Необходимые навыки',
            cancel: 'Отмена',
            save: 'Сохранить вакансию',
            delete: 'Удалить вакансию',
            update: 'Поднять в списке'
        },
        admin: {
            t1: 'Choose what you want to create or ',
            t2: 'select working directory'

        },
        footer: {
            main: 'Main',
            bonuse: 'Share bonuse',
            questions: 'Ask the question',
            team: 'ProductCamp team',
            contact: 'Contacts',
            news: 'News',
            courses: 'Courses',
            vebinars: 'Vebinars',
            knowledges: 'Knowledge base',
            faq: 'FAQ',
            corporate: 'For Buisness',
            oferta: 'Oferta',
            policy: 'Privacy policy',
            contacts: 'Contacts',
            want_email: 'Хочу рассылку',
            email: 'productlandrus@gmail.com',
            phone: '+7 981 975 59 03'
        },
        vacancySetup: {
            filter: 'Фильтр',
            company: 'Компания',
            company_all: 'все компании',
            location: 'Город',
            all_locations: 'Все города',
            period: 'Время публикации',
            experience: 'Опыт',
            level: 'Уровень',
            format: 'Формат',
            format_types: 'Офис / Удаленно / Гибрид',
            salary: 'Зарплата',
            not_relevant: 'not relevant',
            junior: 'junior',
            middle: 'middle',
            senior: 'senior',
            c_level: 'C-Level',
            all_time: 'за все время',
            day: 'за сутки',
            week: 'за неделю',
            month: 'за меcяц',
            experience_0: 'не важно',
            experience_1: 'менее года',
            experience_2: 'от 1 до 3 лет',
            experience_3: 'от 3 до 6 лет',
            experience_6: 'более 6 лет',
            office: 'офис',
            remote: 'удаленно',
            hybrid: 'гибридный',
            salary_1: 'Только с зарплатой',
            salary_0: 'не указана',
            salary_discuss: 'договорная',
            discuss: 'обсуждается',
            reset: 'Сбросить фильтр',
            keys: 'Ключевые навыки',
            edit: 'Редактировать',
            show: 'Вакансия открыта',
            hide: 'Вакансия в архиве',
            delete: 'Удалить',
            show_archive: 'Показать архив',
            vacancy: 'Вакансия',
            internship: 'Стажировка'

        },
        vacancyModal: {
            name: 'Название вакансии',
            city: 'Город',
            short: 'Краткое описание',
            search: 'Поиск тегов',
            link: 'Ссылка на вакансию',
            left: ' из %{variable}',
            add_tag: 'Добавить',
            salary_min: 'от',
            salary_max: 'до'
        },
        tags: {
            manager: 'Tag менеджер'
        },
        sideMenu: {
            tilda: 'Tilda',
            platform: 'Платформа',
            events: 'События',
            career: 'Карьерный хаб',
            coworking: 'Коворкинг',
            academy: 'Академия',
            library: 'Библиотека знаний',
            schedule: 'Программа кэмпа',
            devs: 'DevsCamp',
            doll: 'Долина'
            // podcasts: 'Подкасты',
            // podcasts: 'Подкасты',
        },
        platform: {
            edit: 'On Air',
            room01: 'Зал 1',
            room02: 'Зал 2',
            room03: 'Зал 3'
        },
        schedule: {
            title: 'Расписание докладов',
            edit: 'Редактировать',
            about: 'About',
            speakers: 'Speakers',
            qa: 'Q&A',
            mark: 'Mark',
            search: 'Search by speakers or companies',
            search_placeholder: 'Speakers’ name, surname or company',
            choose_track: 'Choose track',
            choose_type: 'Choose type',
            all_tracks: 'All tracks',
            all_typos: 'All typos',
            saved: 'Saved',
            agenda_sponsor: 'Agenda sponsor',
            broadcast: 'Back to Broadcast',
            track_sponsor: 'Track sponsor'
        },
        talk_typos: {
            report: 'Доклад',
            master_class: 'Мастер-класс',
            qa: 'Q&A',
            networking: 'Нетворкинг',
            workshop: 'Воркшоп',
            interactive: 'Интерактив',
            panel_discussion: 'Круглый стол',
            lighting_talks: 'Lightning Talks',
            report_qa: 'Доклад+Q&A'
        },
        talks_tracks: {
            product_lunch: 'запуск продукта',
            money: 'монетизация',
            analytics: 'аналитика и маркетинг',
            processes: 'процессы и команда',
            mental_health: 'mental & physical health',
            lighting_talks: 'Lightning Talks',
            common: 'общие темы'
        },
        course: {
            t1: 'Курс. Форма управления контентом',
            level: 'Уровень сложности',
            link: 'Ссылка на внешнюю страницу',
            company_id: 'Если материал публикуется от имени компании - выберите компанию из списка и сохраните',
            clear_company_id: 'Удалить связку',
            success: 'Сохранено успешно',
            detach_user: 'Открепить автора',
            duration: 'Duration'
        },
        library: {
            articles: 'Статьи',
            main: 'Главная',
            afisha: 'Афиша',
            multimedia: 'Мультимедиа',
            tools: 'Инструменты',
            create_content: 'Редактировать данные',
            t1: 'Библиотека',
            t2: 'Самые просматриваемые материалы собраны и отсортированы в одном месте',
            add: 'Добавить',
            add_new: 'Изменить информацию',
            title: 'Название',
            description: 'Описание',
            load_new_image: 'Загрузить',
            url: 'Ссылка на материал',
            type0: 'Зона',
            type1: 'Тип контента',
            type2: 'Подтип контента',
            edit: 'Редактировать',
            show: 'Опубликовать',
            hide: 'Скрыть',
            favorite: 'Избранное',
            favorites: 'Добавить в избранное (в разработке)',
            content_type: 'Тип контента',
            companies: 'Компании',
            company_id: 'Компания',
            search: 'Поиск...',
            load_more: 'Еще результаты',
            published_at: 'Дата публикации',
            cover: 'Изображение обложки',
            select_photo: 'Select new',
            remove_photo: 'Remove',
            save: 'Сохранить',
            cancel: 'Отменить',
            delete: 'Удалить',
            delete_confirmation: 'Данное действие необратимо. Вы точно уверены,	 что хотите удалить запись из Базы Данных?',
            new_content: 'New content',
            popular_content: 'Popular content'
        },
        coworking: {
            t1: 'Коворкинг',
            t2: 'Ищем героев, которые будут вместе с нами создавать продукты и сервисы нового поколения для миллионов людей. Сделайте свой вклад в цифровое будущее!',
            afisha: 'Афиша',
            networking: 'Нетворкинг',
            online: 'онлайн',
            offline: 'офлайн',
            session_expert: 'эксперт-сессии',
            kitchen: 'продуктовая кухня',
            pcamp: 'productcamp',
            dcamp: 'devscamp',
            any_month: 'Любой месяц',
            any_city: 'Любой город',
            name: 'Название мероприятия',
            description: 'Описание',
            link: 'Ссылка на внешний лендинг или программу',
            registration: 'Ссылка на регистрацию',
            type: 'Тип мероприятия',
            level: 'Уровень',
            live: 'Формат',
            success: 'Информация успешно обновлена',
            date: 'Дата начала',
            dates: 'Количество дней',
            add: 'Добавить'

        },
        tagsFrame: {
            all: 'All tags: ',
            new: 'Create new Tag',
            placeholder: 'Search in existing tags',
            add: 'Add new',
            manager: 'Tag manager'
        },
        tagNew: {
            fill: 'Fill the form to ',
            update: 'Update',
            create: 'Create',
            new: 'a New ',
            tag: 'Tag',
            tag_name: 'Tag name'
        },
        partners: {
            add: 'Редактировать',
            edit_page: 'Компания. Настройка',
            sber: 'СБЕР',
            cdek: 'СДЕК',
            vtb: 'ВТБ',
            avito: 'Авито',
            sportmaster: 'Спортмастер',
            ps: 'ProductStar',
            vacancy: 'Вакансии',
            tools: 'Инструменты',
            podcasts: 'Подкасты',
            sessions: 'Эксперт-сессии',
            name: 'Русское название',
            slug: 'Название в латинской транскрипции для URL',
            description: 'Описание',
            intro1: 'Первый заговок страницы Партнера',
            intro2: 'Второй заговок страницы Партнера',
            img: 'Изображение',
            image: 'Изображение',
            video: 'Ссылка на видео для заголовка партнерской страницы',
            site: 'Официальный сайт',
            hh: 'Ссылка на профиль компании на Head Hunter',
            logo: 'Логотип (предпочтителен файл формата SVG)',
            side_menu: 'Показывать компанию в боковом меню?',
            banner: 'Баннер',
            twitter: 'twitter',
            fb: 'facebook',
            insta: 'instagram',
            tg: 'telegram',
            vk: 'vk',
            phone: 'telephone',
            email: 'email',
            ok: 'ok',
            user: 'Фото профиля'
        },
        auth: {
            login: 'Log in',
            register: 'Register',
            recover: 'Password',
            reset: 'Reset password',
            confirm_email: 'Email Verification',
            confirm_password: 'Secure Area',
            forget_password: 'Forget password',
            two_factor: 'Two-factor Confirmation'
        },
        top_menu: {
            api: 'API tokens',
            profile: 'Profile',
            manage_account: 'Manage Account'
        },
        delete_user: {
            title: 'Delete Account',
            description: 'Permanently delete your account.',
            message: 'Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.',
            delete_button: 'Delete Account',
            title_modal: 'Delete Account',
            confirmation: 'Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.'
        },
        logout_browsers: {
            title: 'Browser Sessions',
            description: 'Manage and log out your active sessions on other browsers and devices.',
            message: 'If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.',
            this_device: 'This device',
            last_active: 'Last active ',
            action: 'Log Out Other Browser Sessions',
            done: 'Done. ',
            confirm: 'Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.'
        },
        two_factor: {
            title: 'Two Factor Authentication',
            description: 'Add additional security to your account using two factor authentication.',
            message: 'You have enabled two factor authentication.',
            finish: 'Finish enabling two factor authentication.',
            warning: 'When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone\'s Google Authenticator application.',
            scan_phone: 'To finish enabling two factor authentication, scan the following QR code using your phone\'s authenticator application or enter the setup key and provide the generated OTP code.',
            scan_qr: 'Two factor authentication is now enabled. Scan the following QR code using your phone\'s authenticator application or enter the setup key.',
            setup_key: 'Setup Key: ',
            regenerate: 'Regenerate Recovery Codes',
            show: 'Show Recovery Codes',
            store: 'Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.'
        },
        update_profile: {
            title: 'Profile Information',
            description: 'Update your account\'s profile information and email address.',
            select_new: 'Select A New Photo',
            remove: 'Remove Photo',
            unverified: 'Your email address is unverified.',
            resend_verification: 'Click here to re-send the verification email.',
            message: 'A new verification link has been sent to your email address.'
        },
        confirm_password: {
            message: 'This is a secure area of the application. Please confirm your password before continuing.'
        },
        forgot_password: {
            message: 'Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.',
            button: 'Email Password Reset Link',
            header: 'Password recovery',
            status: 'Link has been sent. Check your email please'
        },
        reset_password: {
            button: 'Reset Password'
        },
        two_factor_challenge: {
            phone: 'Please confirm access to your account by entering the authentication code provided by your authenticator application.',
            codes: 'Please confirm access to your account by entering one of your emergency recovery codes.',
            recovery_code: 'Recovery Code',
            use_recovery: 'Use a recovery code',
            use_phone: 'Use an authentication code'
        },
        verify_email: {
            before: 'Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn\'t receive the email, we will gladly send you another.',
            link_sent: 'A new verification link has been sent to the email address you provided in your profile settings.',
            resend: 'Resend Verification Email'
        },
        form_base: {
            team_selector: 'Select the team',
            price: 'Стоимость',
            currency: 'Currency',
            name: 'Name',
            description: 'Description',
            published_at: 'Name',
            logo: 'Logo',
            banner: 'Banner',
            image: 'Image',
            email: 'Электронная почта',
            first_name: 'First Name',
            second_name: 'Second Name',
            last_name: 'Last Name',
            phone: 'Phone',
            tg: 'Telegram',
            full_name: 'Full name',
            city: 'City',
            social: 'Social network',
            birthday: 'Birthday',
            role_selector: 'Select roles',
            contact: 'Contact person',
            role: 'Role',
            password: 'Password',
            age: 'Age',
            zip: 'Zip',
            country: 'Country',
            street_01: 'Line 1',
            street_02: 'Line 2',
            cancel: 'Cancel',
            save: 'Save',
            save_exit: 'Save and exit',
            search: 'Search',
            provide_details: 'Add some details in Parent Component for component render',
            url: 'Direct link',
            load_map: 'Upload map image with admin interface',
            show_map: 'Open map',
            hide_map: 'Hide map',
            broadcast: 'Broadcast url',
            videos: 'Fragments',
            count: 'Count'
        },
        registration_form: {
            first_name: 'Как тебя зовут?*',
            last_name: 'Твоя фамилия*',
            city: 'Из какого ты города?*',
            email: 'Твой e-mail*',
            phone: 'Твой телефон',
            social: 'Ссылка на профиль в социальной сети',
            http_placeholder: 'https://',
            company: 'Из какой ты компании?*',
            product: 'Название продукта?',
            position: 'Твоя роль*',
            tg: 'Ссылка на твой Telegram для оперативной связи (только по орг вопросам)*',
            source: 'Как ты узнал(а) о кэмпе?',
            source_placeholder: 'Рассказали друзья, коллеги, увидел(а) рекламу или отзыв',
            theme: '',
            theme_placeholder: 'Тема попадет в общее голосование среди участников сообщества',
            comments: 'И, если у тебя еще остались силы, пожалуйста напиши нам то, о чем мы забыли спросить или же любой фидбэк. Это важная часть формата - вклад в развитие делает каждый участник сообщества ProductCamp. Спасибо!',
            description: '',
            description_placeholder: 'Ссылка на тезисы',
            co_authors: '',
            co_authors_placeholder: 'Информация о со-докладчиках (при их наличии)',
            want_know: 'О чем было бы интересно послушать?*',
            want_know_placeholder: 'Можно указать несколько тем, через запятую',
            expectations: 'Твои ожидания от кэмпа? Почему хочешь принять участие?*',
            expectations_placeholder: 'Нетворкинг, профессиональный рост, новые знания',
            local: 'Кого из российских компаний\\продуктов хотелось бы послушать на кэмпе?',
            local_placeholder: 'Помоги нам их найти, напиши имя, компанию\\продукт, ссылку на профиль в fb',
            foreign: 'Кого из зарубежных компаний\\продуктов хотелось бы послушать на кэмпе?',
            foreign_placeholder: 'Помоги нам их найти, напиши имя, компанию\\продукт, ссылку на профиль в fb',
            local_search: 'Помоги нам их найти, напиши имя, компанию\\продукт, ссылку на профиль в fb',
            local_search_placeholder: 'Имена/названия и/или ссылки',
            allergy: 'Какие темы вызывают у тебя аллергию и почему?',
            allergy_placeholder: 'Например, саморазвитие',
            qa: 'Задай вопрос, на который тебе важно получить ответ от участников кэмпа.',
            qa_placeholder: 'Поможет для эффективного нетворкинга',
            ask_me: 'Напиши, по каким темам ты можешь помочь участникам кэмпа.',
            ask_me_placeholder: 'Поможет для эффективного нетворкинга',
            presenter: 'Кого ты видишь ведущим(и) открытия и закрытия кэмпа?',
            presenter_placeholder: 'Пожалуйста, предложи один или более вариантов',
            professional_experience: 'Ссылка на LinkedIn или соцсеть, наиболее полно отражающую твой профессиональный опыт',
            channel_resource: 'Если у тебя есть канал/ресурс и тп с тематическим для аудитории кэмпа контентом, то оставь, пожалуйста, ссылку на него'
        },
        scheduleModal: {
            attach: 'Attach',
            detach: 'Detach',
            change: 'Change',
            achivments: 'Achivments',
            date: 'Date',
            info: 'Info',
            speakers: 'Speakers',
            place: 'Place',
            tags: 'Tags',
            join: 'Join spaces?',
            change_info: 'Change info',
            name: 'Title',
            description: 'Description',
            level: 'Level',
            type: 'Typo',
            track: 'Track',
            broadcast_url: 'Broadcast url',
            google_calendar_link: 'Google calendar link',
            slots: 'Slots (10 minutes)',
            slots_amount: 'Slots amount',
            finish_time: 'Finish time',
            finish: 'Finish',
            start_time: 'Start time',
            start: 'Start',
            new_speaker: 'Create Speaker',
            change_speaker: 'Change speaker',
            detach_speaker: 'Detach speaker',
            first_name: 'First name',
            last_name: 'Last name',
            company: 'Company',
            position: 'Job Title',
            link_day: 'Link. Day ',
            edit_info: 'Edit Space Event information',
            space_name: 'Space Title',
            space_description: 'Space Description',
            upsert: 'Update information',
            delete: 'Delete Space',
            create: 'Create Space'
        }
    },

    ru: {
        resume: {
            full_name: 'Имя Фамилия',
            position: 'Должность',
            email: 'Электронная почта',
            company: 'Компания',
            show_resume: 'Подробнее',
            search_placeholder: 'Поиск',
            user_info: 'Информация о пользователе',
            personal_information: 'Личная информация и персональные данные.',
            about: 'Информация',
            link: 'Ссылка на резюме',
            link_description: 'Ссылка будет открыта в новом окне',
            experience: 'Опыт',
            tg: 'Telegram'
        },
        pagination: {
            previous: 'Предыдущая',
            next: 'Следующая',
            showing: 'Показаны записи с',
            to: 'до',
            of: 'из'
        },
        csv: {
            import_data: 'Импорт данных',
            title: 'Пакетная загрузка данных из фалов CSV',
            header: 'Выберите модель для которой предполагается произвести массовую загрузку данных.',
            subtitle: 'После выбора модели и парсинга файла - сопоставьте полученные из файла данные соответствующим полям БД. <br/>Импорт данных и их последующая запись будут произведены только для полей, которым указано явное соответствие. <br/>Все несопоставленные поля будут проигнорированны.',
            subsubtitle: '',
            map_data: 'Определите соответствие данных',
            parsing_results: 'Результаты парсинга',
            select_action: 'Выберите действие из списка',
            proceed_data: 'Загрузить данные',
            alert: 'Необходимо выбрать метод для обработки данных!'
        },
        min: 'мин',
        agenda: 'Расписание докладов',
        pages: {
            profile: 'Профиль'
        },
        login_prompt: 'Для продолжения необходима авторизация.',
        success: 'Успешно',
        member_message: '* Переключатель принадлежности клубу',
        member: 'Клуб',
        foreign: 'Чужой',
        publish: 'Опубликовать',
        unpublish: 'Скрыть',
        was_published: 'Опубликовано',
        was_unpublished: 'Скрыто',
        league_partners: 'Партнеры лиги',
        club_partners: 'Наши партнеры',
        newsletter: 'Подпишитесь на нашу рассылку',
        newsletter_text: 'Свежие новости и информация',
        newsletter_placeholder: 'Электронная почта',
        newsletter_button: 'Подписаться',
        reserved: 'Все права защищены',
        app_name: 'Футбольный клуб Звезда (Санкт-Петербург, Россия)',
        news_other: 'Другие новости',
        rules: 'Правила',
        name: 'Имя',
        password: 'Пароль',
        password_confirmation: 'Подтверждение пароля',
        details_button: 'Подробнее',
        back_button: 'Назад',
        create_button: 'Создать',
        cancel_button: 'Отмена',
        enable_button: 'Включить',
        confirm_button: 'Подтвердить',
        edit_button: 'Редактировать',
        disable_button: 'Отключить',
        save_button: 'Сохранить',
        select_new_button: 'Выбрать новое',
        register_button: 'Зарегистрироваться',
        all_button: 'Все',
        saved: 'Сохранено.',
        edit_profile: 'Редактировать профиль',
        logout: 'Выход',
        story: 'Создайте ваш текст',
        search: 'Поиск по Баз данных',
        hide: 'Скрыть секцию',
        show: 'Развернуть секцию',
        associated_team: 'Связан с командами',
        unlink: 'Отлинковать',
        link: 'Прилинковать',
        only_players: 'Только для Игроков и Тренеров',
        stadium_later: 'Информация о месте проведения уточняется',
        campRegistration: {
            t1: 'Регистрация'
        },
        tableContents: {
            title: 'Программа курса',
            step: 'Ступень ',
            chapter: 'Раздел %{variable}: '
        },
        contact_information: {
            intro: 'Контактная информация',
            description: 'Заполните или измените ваши данные.',
            first_name: 'Имя',
            second_name: 'Отчество',
            last_name: 'Фамилия',
            phone: 'Телефон',
            tg: 'Телеграм',
            city: 'Город проживания',
            social: 'Социальные сети',
            birthday: 'Дата рождения'
        },
        vacancy_request: {
            t1: 'Нет подходящей вакансии?',
            t2: 'Заполни форму, и мы пришлем тебе новые вакансии, как только они появятся:',
            position: 'Позиция',
            agree: 'Согласие на обработку персональных данных',
            request_accepted: 'Заявка отправлена',
            digest: 'Хочу дайджест',
            show_resume: 'Показывать резюме работодателям'
        },
        job_information: {
            intro: 'Информация о работе',
            description: 'Заполните или измените ваши данные.',
            company: 'Компания',
            position: 'Занимаемая должность',
            product: 'Название продукта',
            resume: 'Ссылка на действующее резюме для показа работодателям',
            level: 'Уровень',
            achivments: 'Достижения'
        },
        profile_information: {
            title: 'Профиль',
            intro: 'Информация профиля',
            description: 'Обновите фтографию профиля или адрес электронной почты',
            photo: 'Фотография',
            select_photo: 'Выбрать',
            remove_photo: 'Удалить',
            save: 'Сохранить',
            saved: 'Сохранено.',
            email: 'Email',
            tg: 'Telegram',
            sphere: 'Сфера интересов'
        },
        delete_information: {
            intro: 'Удалить аккаунт',
            description: 'Навсегда удалить данные пользователя',
            alert: 'Перед удалением аккаунта сохраните ваши данные',
            confirm: 'Удалить',
            cancel: 'Отменить',
            confirmation_process: 'Вы уверены, что хотите удалить ваш аккаунт? Все ваши данные будут потеряны безвозвратно. Для подтверждения введите ваш пароль.'
        },
        update_password: {
            title: 'Обновить пароль',
            label_confirm: 'Подтвердите пароль',
            label_new: 'Новый пароль',
            label_current: 'Текущий пароль',
            intro: 'Обновить пароль',
            current: 'Текущий пароль',
            new: 'Новый пароль',
            confirm: 'Подтвердить пароль',
            description: 'Используйте сложный пароль, который нигде более не используется.'
        },
        two_auth: {
            intro: 'Двухфактоная аутентификация',
            description: 'Добавьте повышенную защищенность для вашей учетной записи',
            enabled: 'Вы подключили двухфакторную аутентификацию.',
            finish: 'Завершить подключение двухфактоной авторизации',
            setup: 'Для завершения активации отсканируйте этот QR код своим мобильным телефоном. Либо введите ключ установки и единовременный пароль.',
            setup_key: 'Ключ подтверждения: ',
            confirm: 'Подтвердить',
            cancel: 'Отмена',
            disabled: 'Двухфакторная аутентификация отключена.',
            message: 'После активации двухфакторной аутентификации вам будет необходимо вводить одноразовый код при каждом входе в систему. Вы можете получить этот код на свой телефон используя Google Authenticator.',
            read_qr: 'Двухфакторная аутентификация подключена. Отсканируйте этот QR код используя Google Authenticator на вашем телефоне.',
            code_list: 'Сохраните коды восстановления в защищенном месте. Они могут понадобиться чтобы воостановить доступ к вашему аккаунту если ваш телефон с приложением будет утерян.',
            enable_now: 'Включить',
            disable_now: 'Отключить',
            regenerate: 'Сгенерировать новые коды доступа',
            show_list: 'Показать коды доступа'
        },
        browser_sessions: {
            intro: 'Управление сессиями',
            description: 'Управлениями сессиями на других устройствах.',
            message: 'При необоходимости Вы можете закрыть все активные сессии на любых устройствах, где совершался вход в систему.',
            this_device: 'Это устройство',
            last_active: 'Последняя активность',
            confirm_logout: 'Завершить активные сессии',
            logout_other: 'Завершить активные сессии в других броузерах',
            password_request: 'Пожалуйста введите ваш пароль.',
            done: 'Готово.',
            cancel: 'Отменить'
        },
        login: {
            remember_me: 'Запомнить меня',
            l1: 'Войдите в систему используя ваш Email и пароль или',
            l2: 'пройдите регистрацию',
            r1: 'Регистрация',
            r2: 'Уже регистрировались?',
            email: 'Электронная почта',
            password: 'Пароль',
            password_confirmation: 'Подтвердите пароль',
            remember: 'Запомнить',
            login: 'Вход',
            loginAdaptive: 'Войти',
            register: 'Регистрация',
            manage: 'Управление пользовательскими данными',
            logout: 'Выход',
            profile: 'Профиль',
            create: 'Создать аккаунт',
            forgot: 'Забыли пароль?',
            agree: 'Я согласен с ',
            terms: 'условиями использования ',
            and: ' и ',
            policy: 'политикой обработки частных данных',
            recovery1: 'Забыли пароль? Нет проблем.</br> Введите в поле адрес электронной почты, указанный при регистрации и мы отправим вам ссылку для его востановления.',
            recovery2: 'Ссылка на восстановление пароля отправлена.',
            recovery3: 'Пожалуйста, проверьте вашу электронную почту.',
            send_link: 'Отправить ссылку',
            send_again: 'Отправить еще раз?',
            main: 'На главную',

            tokens: 'API Tокены',
            secure_area: 'Это защищенная часть приложения. Пожалуйста, подтвердите Ваш пароль',
            confirm: 'Подтвердить',
            already: 'Уже регистрировались?',
            verify_request: 'Благодарим за регистрацию! Прежде чем начать, подтвердите пожалуйста Ваш адрес электронной почты. Для этого перейдите по ссылке, которую мы отправили Вам на электронную почту. Если вы не получили письмо, мы вышлем его еще раз.',
            verify_again: 'Отправить ссылку',
            status: 'Мы отправили вам ссылку для подтверждения пароля. Пожалуйста проверьте Вашу электронную почту',
            reset: 'Сбросить пароль',
            two_factors1: 'Пожалуйста подтвердите вход в ваш аккаунт - введите код из приложения авторизации.',
            two_factors2: 'Пожалуйста подтвердите вход в ваш аккаунт - введите один из резервных кодов.',
            code_1: 'Использовать резервный код',
            code_2: 'Использовать код авторизации через приложение',
            code: 'Код',
            recovery_code: 'Резервный код'
        },
        hachathonRegistrationPromt: {
            t1: 'Привет. Похоже Вы не вошли в систему!',
            t2: 'Чтобы принять участие в Хакатоне необходимо представиться.<br/>Мы предлагаем пройти базовую регистрацию, которая позволит Вам быть в курсе всех новостей и предстоящих событий.<br/>Кроме того, результаты участия в наших активностях будут сохраняться в вашем личном кабинете.<br/>Либо вы можете просто зарегистрироваться на предстоящий Хакатон.',
            reg_platform: 'Регистрация на платформе',
            reg_hackathon: 'Регистрация на Хакатон'
        },
        form_validation: {
            required: 'Field is required',
            minimum_length: 'Minimum length mismatch'
        },
        registration: {
            registration: 'Регистрация',
            login: 'Авторизация',
            forgot_password_message: 'Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.',
            forgot_password_btn: 'Email Password Reset Link'

        },
        hr: {
            t1: 'HR центр'
        },
        scoring: {
            t1: 'Скоринг навыков'
        },
        academy: {
            t1: 'Академия',
            t2: 'Бесплатные уроки для продакт менеджеров, разработчиков и аналитиков',
            add: 'Добавить',
            courses: 'Курсы',
            materials: 'Материалы',
            seminars: 'Семинары',
            search_placeholder: 'Поиск по курсам и урокам',
            branch: 'Направление',
            level: 'Выбери свой уровень',
            type: 'Тип обучения',
            theme: 'Тема'
        },
        events: {
            events: 'События'
        },
        networking: {
            networking: 'Нетворкинг'
        },
        request: 'Оставить заявку',
        menu_name: 'меню',
        coming_soon: 'Скоро',
        telegram: 'Телеграм канал',
        follow: 'Следить за обновлениями',
        save: 'Сохранить',
        save_exit: 'Сохранить и закрыть',
        cancel: 'Отменить',
        btn_edit: 'Edit',
        btn_edit_info: 'Edit information',
        btn_delete: 'Delete',
        first_name: 'Имя',
        last_name: 'Фамилия',
        phone: 'Телефон',
        tg: 'Телеграм',
        email: 'Электронная почта',
        position: 'Позиция / специализация',
        role: 'Роль',
        other: 'Другое',
        front: 'Front-end',
        back: 'Back-end',
        full: 'Full-stack',
        design: 'Дизайнер',
        marketing: 'Маркетолог',
        pm: 'Продакт Менеджер',
        role_placeholder: 'Ваша специализация если ее нет в списке',
        hackathonRegistrationForm: {
            step01: 'Шаг 1. Информация',
            complete01: 'Пройти индивидуальную регистрацию',
            update: 'Обновить',
            forward: 'Далее',
            alert: 'Введенный вами адрес электронной почты уже зарегистрирован в системе.<br/>Для участия в мероприятии пожалуйста пройдите авторизацию.',
            team: 'Есть команда',
            no_team: 'Нет команды',
            create_team: 'Шаг 2. Собери команду',
            about: 'Шаг 2. Коротко о себе',
            new_member: 'Новый участник',
            add_member: 'Добавить участника',
            remove_mate: 'Удалить участника',
            remove_member: 'Удалить участника из состава команды',
            save_team: 'Сохранить команду и отправить уведомления',
            user_exists: 'Зарегистирован',
            new_user: 'Новый пользователь',
            reminder: 'Не забудьте уведомить друга, что вы зарегистрировали его на мероприятие',
            command_name: 'Название команды',
            company: 'Компания',
            product: 'Продукт',
            position: 'Позиция',
            level: 'Уровень',
            indi: 'Спасибо за регистрацию. Команда сформируется автоматически.',
            command: 'Спасибо за регистрацию.'
        },
        hFooter: {
            t1: 'Дополнительная информация',
            about: 'About',
            contact: 'Contact',
            FAQ: 'FAQ',
            social: 'We are on social networks: '
        },
        hackathonAdminPage: {
            name: 'Название мероприятия',
            team_name: 'Название команды',
            save: 'Сохранить',
            description: 'Описание мероприятия',
            link: 'Ссылка на мероприятие',
            type: 'Тип мероприятия',
            level: 'Уровень сложности',
            registration: 'Ссылка для регистрации',
            img: 'Логотип',
            data: 'Дата',
            productcamp: 'ProductCamp',
            hackathon: 'Хакатон',
            ideathon: 'Идеатон',
            producthon: 'Продуктон',
            junior: 'Junior',
            middle: 'Middle',
            senior: 'Senior',
            all: 'Для всех',
            open_form: 'Кликните на название чтобы перети к редактированию информации',
            all_tasks: 'Все задачи',
            all_teams: 'Зарегистрированные команды',
            add_team: 'Сформировать новую команду',
            back: 'Обратно к списку команд',
            free_participants: 'Нераспределенные участники',
            not_registered: 'Пользователь не заполнил личные данные'
        },
        hackathonTeam: {
            assignment_send: 'На проверку',
            assignment_placeholder: 'Ссылка на готовое задание',
            back: 'Назад',
            take_part: 'Принять участие'
        },
        menu: {
            login: 'Вход',
            events: 'События',
            edit: 'Редактировать событие',
            schedule: 'Расписание',
            schedule_admin: 'Редактировать расписание',
            admin: 'Управление',
            networking: 'Нетворкинг',
            career: 'Карьерный хаб',
            event_registration: 'Карта участника',
            logout: 'Выход',
            map: 'Карта',
            get_update: 'Подписаться на обновления'
        },
        career: {
            t1: 'Карьерный хаб',
            t2: 'Открытые вакансии наших партнеров',
            archive: 'Вакансии в архиве',
            desk1: 'Доска вакансий',
            desk2: 'HeadHunter & ProductCamp’21',
            desk3: 'Изучи вакансии партнеров и друзей HeadHunter, выбери то, что подходит тебе, и подай заявку',
            contract: 'договорная',
            add: 'Добавить вакансию',
            create: 'Создать/изменить вакансию',
            vacancy: 'Вакансия',
            name: 'Название',
            description: 'Описание вакансии',
            link: 'Ссылка на вакансию на HH или внутренний ресурс компании',
            company_id: 'Компания',
            location: 'Город',
            salary_min: 'Зарплата от',
            salary_max: 'Зарплата до',
            level: 'Уровень',
            office: 'Формат',
            experience: 'Опыт',
            type: 'Категория',
            status: 'Статус',
            published_at: 'Активна',
            open_full_form: 'Открыть дополнительные поля',
            close_full_form: 'Скрыть дополнительные поля',
            about: 'О продукте',
            tasks: 'Задачи',
            requirements: 'Требования',
            conditions: 'Условия',
            skills: 'Необходимые навыки',
            cancel: 'Отмена',
            save: 'Сохранить вакансию',
            delete: 'Удалить вакансию',
            update: 'Поднять в списке'
        },
        admin: {
            t1: 'Choose what you want to create or ',
            t2: 'select working directory'

        },
        footer: {
            main: 'Main',
            bonuse: 'Share bonuse',
            questions: 'Ask the question',
            team: 'ProductCamp team',
            contact: 'Contacts',
            news: 'News',
            courses: 'Courses',
            vebinars: 'Vebinars',
            knowledges: 'Knowledge base',
            faq: 'FAQ',
            corporate: 'For Buisness',
            oferta: 'Oferta',
            policy: 'Privacy policy',
            contacts: 'Contacts',
            want_email: 'Хочу рассылку',
            email: 'productlandrus@gmail.com',
            phone: '+7 981 975 59 03'
        },
        vacancySetup: {
            filter: 'Фильтр',
            company: 'Компания',
            company_all: 'все компании',
            location: 'Город',
            all_locations: 'Все города',
            period: 'Время публикации',
            experience: 'Опыт',
            level: 'Уровень',
            format: 'Формат',
            format_types: 'Офис / Удаленно / Гибрид',
            salary: 'Зарплата',
            not_relevant: 'not relevant',
            junior: 'junior',
            middle: 'middle',
            senior: 'senior',
            c_level: 'C-Level',
            all_time: 'за все время',
            day: 'за сутки',
            week: 'за неделю',
            month: 'за меcяц',
            experience_0: 'не важно',
            experience_1: 'менее года',
            experience_2: 'от 1 до 3 лет',
            experience_3: 'от 3 до 6 лет',
            experience_6: 'более 6 лет',
            office: 'офис',
            remote: 'удаленно',
            hybrid: 'гибридный',
            salary_1: 'Только с зарплатой',
            salary_0: 'не указана',
            salary_discuss: 'договорная',
            discuss: 'обсуждается',
            reset: 'Сбросить фильтр',
            keys: 'Ключевые навыки',
            edit: 'Редактировать',
            show: 'Вакансия открыта',
            hide: 'Вакансия в архиве',
            delete: 'Удалить',
            show_archive: 'Показать архив',
            vacancy: 'Вакансия',
            internship: 'Стажировка'
        },
        vacancyModal: {
            name: 'Название вакансии',
            city: 'Город',
            short: 'Краткое описание',
            search: 'Поиск тегов',
            link: 'Ссылка на вакансию',
            left: ' из %{variable}',
            add_tag: 'Добавить',
            salary_min: 'от',
            salary_max: 'до'
        },
        tags: {
            manager: 'Tag менеджер'
        },
        sideMenu: {
            tilda: 'Tilda',
            platform: 'Платформа',
            events: 'События',
            career: 'Карьерный хаб',
            coworking: 'Коворкинг',
            afisha: 'Афиша',
            academy: 'Академия',
            library: 'Библиотека знаний',
            schedule: 'Программа кэмпа',
            devs: 'DevsCamp',
            doll: 'Долина'
            // podcasts: 'Подкасты',
            // podcasts: 'Подкасты',
        },
        platform: {
            edit: 'On Air',
            room01: 'Зал 1',
            room02: 'Зал 2',
            room03: 'Зал 3'
        },
        schedule: {
            title: 'Расписание докладов',
            edit: 'Редактировать',
            about: 'Информация',
            speakers: 'Докладчики',
            qa: 'Q&A',
            mark: 'Оценить доклад',
            search: 'Поиск по спикеру или компании',
            search_placeholder: 'Имя спикера или название компании',
            choose_track: 'Выбрать трек',
            choose_type: 'Выбрать тип',
            all_tracks: 'Все треки',
            all_typos: 'Все типы',
            saved: 'Сохраненное',
            agenda_sponsor: 'Спонсор расписания',
            broadcast: 'Назад к трансляции',
            track_sponsor: 'Спонсор трека'
        },
        talk_typos: {
            report: 'Доклад',
            master_class: 'Мастер-класс',
            qa: 'Q&A',
            networking: 'Нетворкинг',
            workshop: 'Воркшоп',
            interactive: 'Интерактив',
            panel_discussion: 'Круглый стол',
            lighting_talks: 'Lightning Talks',
            report_qa: 'Доклад+Q&A'
        },
        talks_tracks: {
            product_lunch: 'запуск продукта',
            money: 'монетизация',
            analytics: 'аналитика и маркетинг',
            processes: 'процессы и команда',
            mental_health: 'mental & physical health',
            lighting_talks: 'Lightning Talks',
            common: 'общие темы'
        },
        course: {
            t1: 'Курс. Форма управления контентом',
            level: 'Уровень сложности',
            link: 'Ссылка на внешнюю страницу',
            company_id: 'Если материал публикуется от имени компании - выберите компанию из списка и сохраните',
            clear_company_id: 'Удалить связку',
            success: 'Сохранено успешно',
            detach_user: 'Открепить автора',
            duration: 'Продолжительность'
        },
        library: {
            articles: 'Статьи',
            main: 'Главная',
            afisha: 'Афиша',
            multimedia: 'Мультимедиа',
            tools: 'Инструменты',
            create_content: 'Редактировать данные',
            t1: 'Библиотека',
            t2: 'Самые просматриваемые материалы собраны и отсортированы в одном месте',
            add: 'Добавить',
            add_new: 'Изменить информацию',
            title: 'Название',
            description: 'Описание',
            load_new_image: 'Загрузить',
            url: 'Ссылка на материал',
            type0: 'Зона',
            type1: 'Тип контента',
            type2: 'Подтип контента',
            edit: 'Редактировать',
            show: 'Опубликовать',
            hide: 'Скрыть',
            favorite: 'Избранное',
            favorites: 'Добавить в избранное (в разработке)',
            content_type: 'Тип контента',
            companies: 'Компании',
            company_id: 'Компания',
            search: 'Поиск...',
            load_more: 'Еще результаты',
            published_at: 'Дата публикации',
            cover: 'Изображение обложки',
            select_photo: 'Select new',
            remove_photo: 'Remove',
            save: 'Сохранить',
            cancel: 'Отменить',
            delete: 'Удалить',
            delete_confirmation: 'Данное действие необратимо. Вы точно уверены,	 что хотите удалить запись из Базы Данных?',
            new_content: 'Новое',
            popular_content: 'Популярное'
        },
        coworking: {
            t1: 'Коворкинг',
            t2: 'Ищем героев, которые будут вместе с нами создавать продукты и сервисы нового поколения для миллионов людей. Сделайте свой вклад в цифровое будущее!',
            afisha: 'Афиша',
            networking: 'Нетворкинг',
            online: 'онлайн',
            offline: 'офлайн',
            session_expert: 'эксперт-сессии',
            kitchen: 'продуктовая кухня',
            pcamp: 'productcamp',
            dcamp: 'devscamp',
            any_month: 'Любой месяц',
            any_city: 'Любой город',
            name: 'Название мероприятия',
            description: 'Описание',
            link: 'Ссылка на внешний лендинг или программу',
            registration: 'Ссылка на регистрацию',
            type: 'Тип мероприятия',
            level: 'Уровень',
            live: 'Формат',
            success: 'Информация успешно обновлена',
            date: 'Дата начала',
            dates: 'Количество дней',
            add: 'Добавить'

        },
        afisha: {
            add: 'Добавить',
            t1: 'Афиша продуктовых событий',
            t2: 'События, мероприятия и встречи из мира продуктового менеджмента, стартапов и диджитал индустрии.'
        },
        tools: {
            t1: 'Инструменты',
            t2: 'Тулзы, тренажеры, симуляторы, фреймворки, шаблоны для продуктового менеджера'
        },
        video: {
            t1: 'Видео',
            t2: 'Лучшие выступления продуктовых менеджеров, разбор практических кейсов'
        },
        articles: {
            add: 'Добавить',
            t1: 'Статьи',
            t2: 'Полезные статьи и материалы обмен опытом в области product management'
        },
        vacancy: {
            t1: 'Вакансии',
            t2: 'Открытые вакансии наших партнеров'
        },
        tagsFrame: {
            all: 'All tags: ',
            new: 'Create new Tag',
            placeholder: 'Search in existing tags',
            add: 'Add new',
            manager: 'Tag manager'
        },
        tagNew: {
            fill: 'Fill the form to ',
            update: 'Update',
            create: 'Create',
            new: 'a New ',
            tag: 'Tag',
            tag_name: 'Tag name'
        },
        partners: {
            add: 'Редактировать',
            edit_page: 'Компания. Настройка',
            sber: 'СБЕР',
            cdek: 'СДЕК',
            vtb: 'ВТБ',
            avito: 'Авито',
            sportmaster: 'Спортмастер',
            ps: 'ProductStar',
            vacancy: 'Вакансии',
            tools: 'Инструменты',
            podcasts: 'Подкасты',
            sessions: 'Эксперт-сессии',
            name: 'Русское название',
            slug: 'Название в латинской транскрипции для URL',
            description: 'Описание',
            intro1: 'Первый заговок страницы Партнера',
            intro2: 'Второй заговок страницы Партнера',
            img: 'Изображение',
            image: 'Изображение',
            video: 'Ссылка на видео для заголовка партнерской страницы',
            site: 'Официальный сайт',
            hh: 'Ссылка на профиль компании на Head Hunter',
            logo: 'Логотип (предпочтителен файл формата SVG)',
            side_menu: 'Показывать компанию в боковом меню?',
            banner: 'Баннер',
            twitter: 'twitter',
            fb: 'facebook',
            insta: 'instagram',
            tg: 'telegram',
            vk: 'vk',
            phone: 'telephone',
            email: 'email',
            ok: 'ok',
            user: 'Фото профиля'
        },
        auth: {
            login: 'Вход',
            register: 'Регистрация',
            recover: 'Восстановление пароля',
            reset: 'Сбросить пароль',
            confirm_email: 'Подтвердить email',
            confirm_password: 'Подтвердить пароль',
            forget_password: 'Забыли пароль',
            two_factor: 'Двух этапная верификация'
        },
        top_menu: {
            api: 'API токены',
            profile: 'Профиль',
            manage_account: 'Управление аккаунтом'
        },
        delete_user: {
            title: 'Удалить аккаунт',
            description: 'Полностью удалить ваш аккаунт.',
            message: 'После удаления вашего аккаунта все его ресурсы и данные будут удалены навсегда. Перед удалением аккаунта, пожалуйста, скачайте любые данные или информацию, которую вы хотите сохранить.',
            delete_button: 'Удалить аккаунт',
            title_modal: 'Удалить аккаунт',
            confirmation: 'Вы уверены, что хотите удалить свой аккаунт? После удаления аккаунта все его ресурсы и данные будут удалены навсегда. Пожалуйста, введите ваш пароль, чтобы подтвердить, что вы хотите удалить аккаунт навсегда.'
        },
        logout_browsers: {
            title: 'Сеансы в браузере',
            description: 'Управление и выход из активных сеансов на других браузерах и устройствах.',
            message: 'При необходимости вы можете выйти из всех других сеансов браузера на всех ваших устройствах. Ниже перечислены некоторые из ваших недавних сеансов, но этот список может быть не исчерпывающим. Если у вас есть подозрение, что ваш аккаунт был взломан, вам также следует обновить пароль.',
            this_device: 'Это устройство',
            last_active: 'Последняя активность ',
            action: 'Выйти из других сеансов браузера',
            done: 'Готово. ',
            confirm: 'Пожалуйста, введите ваш пароль, чтобы подтвердить, что вы хотите выйти из других сеансов браузера на всех ваших устройствах.'
        },
        two_factor: {
            title: 'Двухфакторная аутентификация',
            description: 'Добавьте дополнительную защиту для вашего аккаунта с помощью двухфакторной аутентификации.',
            message: 'Вы включили двухфакторную аутентификацию.',
            finish: 'Завершить включение двухфакторной аутентификации.',
            warning: 'Когда включена двухфакторная аутентификация, вас будут просить ввести безопасный случайный токен во время аутентификации. Вы можете получить этот токен из приложения Google Authenticator на вашем телефоне.',
            scan_phone: 'Чтобы завершить включение двухфакторной аутентификации, отсканируйте следующий QR-код с помощью приложения для аутентификации на вашем телефоне или введите установочный ключ и введите сгенерированный OTP-код.',
            scan_qr: 'Теперь двухфакторная аутентификация включена. Отсканируйте следующий QR-код с помощью приложения для аутентификации на вашем телефоне или введите установочный ключ.',
            setup_key: 'Ключ настройки: ',
            regenerate: 'Сгенерировать коды восстановления заново',
            show: 'Показать коды восстановления',
            store: 'Сохраните эти коды восстановления в безопасном менеджере паролей. Они могут быть использованы для восстановления доступа к вашему аккаунту, если устройство двухфакторной аутентификации будет утеряно.'
        },
        update_profile: {
            title: 'Информация о профиле',
            description: 'Обновите информацию о профиле и адрес электронной почты вашего аккаунта.',
            select_new: 'Выберите новое фото',
            remove: 'Удалить фото',
            unverified: 'Ваш адрес электронной почты не подтвержден.',
            resend_verification: 'Нажмите здесь, чтобы повторно отправить письмо с подтверждением.',
            message: 'На ваш адрес электронной почты была отправлена новая ссылка для подтверждения.'
        },
        confirm_password: {
            message: 'Это безопасная область приложения. Пожалуйста, подтвердите ваш пароль, прежде чем продолжить.'
        },
        forgot_password: {
            message: 'Забыли пароль? Нет проблем. Просто укажите свой адрес электронной почты, и мы отправим вам ссылку для сброса пароля, которая позволит вам выбрать новый пароль.',
            button: 'Отправить ссылку для сброса пароля по электронной почте',
            header: 'Восстановление пароля',
            status: 'Ссылка отправлена, проверьте вашу почту пожалуйста'
        },
        reset_password: {
            button: 'Сбросить пароль'
        },
        two_factor_challenge: {
            phone: 'Пожалуйста, подтвердите доступ к вашему аккаунту, введя код аутентификации, предоставленный вашим приложением для аутентификации.',
            codes: 'Пожалуйста, подтвердите доступ к вашему аккаунту, введя один из ваших аварийных кодов восстановления.',
            recovery_code: 'Код восстановления',
            use_recovery: 'Использовать код восстановления',
            use_phone: 'Использовать код аутентификации'
        },
        verify_email: {
            before: 'Прежде чем продолжить, можете ли вы подтвердить свой адрес электронной почты, перейдя по ссылке, которую мы только что отправили вам по электронной почте? Если вы не получили письмо, мы с удовольствием отправим вам еще одно.',
            link_sent: 'На адрес электронной почты, указанный в настройках вашего профиля, была отправлена новая ссылка для подтверждения.',
            resend: 'Отправить повторно письмо с подтверждением'
        },
        form_base: {
            team_selector: 'Выбрать команду',
            price: 'Стоимость',
            currency: 'Валюта',
            name: 'Название',
            description: 'Описание',
            published_at: 'Дата публикации|видимость',
            logo: 'Логотип',
            banner: 'Баннер',
            image: 'Изображения',
            info: 'Информация',
            details: 'Детали',
            email: 'Электронная почта',
            first_name: 'Имя',
            second_name: 'Отчество',
            last_name: 'Фамилия',
            phone: 'Телефон',
            tg: 'Телеграм',
            full_name: 'ФИО',
            city: 'Город проживания',
            social: 'Социальные сети',
            birthday: 'Дата рождения',
            role_selector: 'Выбрать роли',
            contact: 'Контакт',
            role: 'Роль',
            password: 'Пароль',
            age: 'Возраст',
            zip: 'Почтовый индекс',
            country: 'Страна',
            street_01: 'Адрес строка 1',
            street_02: 'Адрес строка 2',
            cancel: 'Отменить',
            save: 'Сохранить',
            save_exit: 'Сохранить и выйти',
            search: 'Поиск',
            provide_details: 'Для отображения компонента необходимо определить делали в Parent Component',
            url: 'Прямая ссылка',
            date: 'Дата матча',
            time: 'Время матча',
            team_a: 'Команда A',
            team_b: 'Команда B',
            select_stadium: 'Выбрать стадион из списка',
            select_team: 'Выбрать команду из списка',
            load_map: 'Карта не загружена',
            show_map: 'Открыть карту',
            hide_map: 'Закрыть карту',
            broadcast: 'Ссылка на трансляцию',
            videos: 'Фрагменты',
            count: 'Счет'
        },
        registration_form: {
            first_name: 'Как тебя зовут?*',
            last_name: 'Твоя фамилия*',
            city: 'Из какого ты города?*',
            email: 'Твой e-mail*',
            phone: 'Твой телефон',
            social: 'Ссылка на профиль в социальной сети',
            http_placeholder: 'https://',
            company: 'Из какой ты компании?*',
            product: 'Название продукта?',
            position: 'Твоя роль*',
            tg: 'Ссылка на твой Telegram для оперативной связи (только по орг вопросам)*',
            source: 'Как ты узнал(а) о кэмпе?',
            source_placeholder: 'Рассказали друзья, коллеги, увидел(а) рекламу или отзыв',
            theme: '',
            theme_placeholder: 'Тема попадет в общее голосование среди участников сообщества',
            comments: 'И, если у тебя еще остались силы, пожалуйста напиши нам то, о чем мы забыли спросить или же любой фидбэк. Это важная часть формата - вклад в развитие делает каждый участник сообщества ProductCamp. Спасибо!',
            description: '',
            description_placeholder: 'Ссылка на тезисы',
            co_authors: '',
            co_authors_placeholder: 'Информация о со-докладчиках (при их наличии)',
            want_know: 'О чем было бы интересно послушать?*',
            want_know_placeholder: 'Можно указать несколько тем, через запятую',
            expectations: 'Твои ожидания от кэмпа? Почему хочешь принять участие?*',
            expectations_placeholder: 'Нетворкинг, профессиональный рост, новые знания',
            local: 'Кого из российских компаний\\продуктов хотелось бы послушать на кэмпе?',
            local_placeholder: 'Помоги нам их найти, напиши имя, компанию\\продукт, ссылку на профиль в fb',
            foreign: 'Кого из зарубежных компаний\\продуктов хотелось бы послушать на кэмпе?',
            foreign_placeholder: 'Помоги нам их найти, напиши имя, компанию\\продукт, ссылку на профиль в fb',
            local_search: 'Помоги нам их найти, напиши имя, компанию\\продукт, ссылку на профиль в fb',
            local_search_placeholder: 'Имена/названия и/или ссылки',
            allergy: 'Какие темы вызывают у тебя аллергию и почему?',
            allergy_placeholder: 'Например, саморазвитие',
            qa: 'Задай вопрос, на который тебе важно получить ответ от участников кэмпа.',
            qa_placeholder: 'Поможет для эффективного нетворкинга',
            ask_me: 'Напиши, по каким темам ты можешь помочь участникам кэмпа.',
            ask_me_placeholder: 'Поможет для эффективного нетворкинга',
            presenter: 'Кого ты видишь ведущим(и) открытия и закрытия кэмпа?',
            presenter_placeholder: 'Пожалуйста, предложи один или более вариантов',
            professional_experience: 'Ссылка на LinkedIn или соцсеть, наиболее полно отражающую твой профессиональный опыт',
            channel_resource: 'Если у тебя есть канал/ресурс и тп с тематическим для аудитории кэмпа контентом, то оставь, пожалуйста, ссылку на него'
        },
        scheduleModal: {
            attach: 'Привязать',
            detach: 'Отвязать',
            change: 'Изменить',
            achivments: 'Достижения ',
            date: 'Дата',
            info: 'Информация',
            speakers: 'Спикеры',
            place: 'Зал',
            tags: 'Теги',
            join: 'Oбъединить залы?',
            change_info: 'Изменить данные',
            name: 'Название',
            description: 'Description',
            level: 'Уровень сложности',
            type: 'Тип',
            track: 'Трек',
            broadcast_url: 'Ссылка на запись',
            google_calendar_link: 'Ссылка на google календарь',
            slots: 'Слоты (1 = 10 минут)',
            slots_amount: 'оличество слотов',
            finish_time: 'Время окончания',
            finish: 'oкончаниe',
            start_time: 'Время начала',
            start: 'начало',
            new_speaker: 'Создать спикера',
            change_speaker: 'Изменить спикера',
            detach_speaker: 'Открепить',
            first_name: 'Имя',
            last_name: 'Фамилия',
            company: 'Компания',
            position: 'Должность',
            link_day: 'Ссылка. День ',
            edit_info: 'Редактировать информацию Зала',
            space_name: 'Название Зала',
            space_description: 'Описание',
            upsert: 'Обновить информацию',
            delete: 'Удалить пространство',
            create: 'Создать пространство'
        }
    }
};

